import { ChevronDownIcon } from '@strike-apps/shared/icons'
import { BaseMenuButton, Button, Menu, MenuItem, MenuList } from '@strike-apps/shared/ui'
import type { ReactNode } from 'react'
import { type ReactElement } from 'react'

import { type UserMode } from '../context/UserSettingsContext'

import { type MenuItemProps } from '@strike-apps/shared/ui'

interface LinkMenuItemProps extends MenuItemProps {
  href: string
}

export interface SidebarProps {
  children?: ReactNode
}

interface SidebarMenuProps extends SidebarProps {
  activeRoute: string
  mode: UserMode

  onClick?: () => void
  renderLink?: (children: ReactElement, href: string) => ReactElement
}
const LinkMenuItem = (props: LinkMenuItemProps) => {
  return (
    <MenuItem
      as="a"
      textDecoration="none"
      sx={{
        '&:hover': { textDecoration: 'none' },
      }}
      {...props}
    />
  )
}

export const DropdownMenu = ({ activeRoute, mode }: SidebarMenuProps) => {
  return (
    <Menu placement="auto-start">
      <BaseMenuButton
        as={Button}
        rightIcon={<ChevronDownIcon />}
        p={0}
        minW="auto"
        height="30"
        sx={{
          fontSize: 20,
          lineHeight: 24,
          fontWeight: '500',
          letterSpacing: '0',
        }}
        _focus={{
          outline: 'none',
        }}
        variant="ghost"
      >
        {activeRoute}
      </BaseMenuButton>
      <MenuList minW="calc(100vw - 48px)">
        <LinkMenuItem href="/">Home</LinkMenuItem>

        <LinkMenuItem href="/transactions">Activity</LinkMenuItem>

        {mode === 'merchant' && <LinkMenuItem href="/accounts">Accounts</LinkMenuItem>}
      </MenuList>
    </Menu>
  )
}
