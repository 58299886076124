import { Grid, GridItem, Icon, Show } from '@chakra-ui/react'
import { StrikeLogoIcon } from '@strike-apps/shared/icons'
import { Flex, Text } from '@strike-apps/shared/ui'
import React, { type FC, type ReactNode } from 'react'
import { useWideModeBreakpoint } from '../molecules/Sidebar/hooks'
import { Footer } from './Footer'
import { SandboxBadge } from '../SandboxBadge'
export interface PageProps {
  children: ReactNode
  title?: ReactNode
  headerContent?: React.ReactElement
  headerStickyContent?: React.ReactNode
  sidebarContent?: React.ReactElement
}

export const Page: FC<PageProps> = ({
  children,
  title,
  headerContent,
  headerStickyContent,
  sidebarContent,
}) => {
  const wideMode = useWideModeBreakpoint()

  return (
    <Grid
      h="100vh"
      gridTemplateColumns={{ base: '60px 1fr', [wideMode]: '176px 1fr' }}
      gridTemplateRows="68px 1fr 65px"
    >
      <Show above="sm">
        <GridItem rowStart={1} bg="layerPrimary" colStart={1}>
          <Flex alignItems="center" justify={['center', 'flex-start']} height="100%" pl={5}>
            <Icon as={StrikeLogoIcon} color="facePrimary" boxSize={5} />
          </Flex>
        </GridItem>

        <GridItem rowStart={2} colStart={1} rowSpan={2} bg="layerPrimary">
          {sidebarContent}
        </GridItem>
      </Show>
      <GridItem
        colStart={{
          base: 1,
          sm: 2,
        }}
        colSpan={2}
        borderBottomColor="borderPrimary"
        borderBottomWidth="1px"
      >
        <Flex h="100%" justifyContent="space-between" alignItems="center" px={6}>
          <Text role="heading" aria-level={1} variant="title4" color="facePrimary">
            {title}
          </Text>
          <SandboxBadge mr="auto" ml={title ? 3 : 0} />
          {headerContent}
        </Flex>
      </GridItem>

      <GridItem
        rowStart={2}
        rowSpan={3}
        colStart={{
          base: 1,
          sm: 2,
        }}
        bg="transparent"
        colSpan={3}
        overflowX="auto"
      >
        <Flex flexDirection="column" justifyContent="space-between" h="100%">
          <Flex
            flexDirection="column"
            flexGrow={1}
            px={6}
            py={{
              base: 6,
            }}
          >
            {headerStickyContent && (
              <Flex mb={2} position="sticky" top={6} zIndex={3} align="center" justify="center">
                {headerStickyContent}
              </Flex>
            )}
            <Flex flex={1} justify="center">
              {children}
            </Flex>
          </Flex>
          <Footer />
        </Flex>
      </GridItem>
    </Grid>
  )
}
