import { getIdpLogoutUrl } from '@strike-apps/commerce-dashboard/util-auth/logout-url'
import { useCallback } from 'react'
import { useSession } from 'next-auth/react'
import { useEnvSettings } from '@strike-apps/commerce-dashboard/env-settings'

export const useSignOut = () => {
  const { data: session } = useSession()
  const envSettings = useEnvSettings()

  return useCallback(() => {
    window.location.href = session ? getIdpLogoutUrl(session, envSettings) : '/login'
  }, [envSettings, session])
}
