import { useQuery } from '@tanstack/react-query'
import { fetch, type StrikeFetchError } from '@strike-apps/strike/fetch'
import type { ErrorDetails } from '@strike-apps/shared/next-api/models'
import type { UserGetTaskResponse } from '@ln-zap/consumer-api'
import { GET_USER_TASK } from './queries'

export const useUserTask = (isEnabled: boolean) => {
  return useQuery<UserGetTaskResponse, StrikeFetchError<ErrorDetails>>(
    [GET_USER_TASK],
    () => fetch<UserGetTaskResponse>('/api/user/task', { method: 'GET' }),
    {
      enabled: isEnabled,
      staleTime: 5000,
    },
  )
}
