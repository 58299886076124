import {
  AlertDescription,
  AlertTitle,
  Alert as BaseAlert,
  Box,
  CloseButton,
  useDisclosure,
} from '@chakra-ui/react'
import { InfoIcon } from '@strike-apps/shared/icons'
import { Flex, Icon } from '@strike-apps/shared/ui'

export type SimpleAlertProps = {
  title: React.ReactNode
  description?: React.ReactNode
  icon?: React.FC
  iconComponent?: React.ReactNode
  onAction?: () => void
  onDismiss?: () => void
  variant?: 'error' | 'normal'
}

export function SimpleAlert({
  title,
  description,
  icon = InfoIcon,
  iconComponent,
  onAction,
  onDismiss,
  variant,
}: SimpleAlertProps) {
  const { isOpen: isVisible, onClose } = useDisclosure({ defaultIsOpen: true })

  return isVisible ? (
    <BaseAlert
      onClick={onAction}
      cursor={onAction ? 'pointer' : 'default'}
      variant={variant}
      colorScheme="secondary"
      bg="layerPrimary"
      _hover={onAction ? { bg: 'layerPrimaryHover' } : {}}
      gap={{
        base: 3,
        md: 0,
      }}
    >
      {!iconComponent && (
        <Icon
          alignSelf="flex-start"
          as={icon}
          mb={{
            base: 1,
            md: 0,
          }}
          mr={2}
          mt={1}
          color="facePrimary"
        />
      )}
      <Box mr={2} mt={1} alignSelf="flex-start">
        {iconComponent}
      </Box>
      <Flex direction="column">
        <AlertTitle>{title}</AlertTitle>
        {description && <AlertDescription textAlign="left">{description}</AlertDescription>}
      </Flex>
      <Flex
        ml={{
          base: 0,
          md: 'auto',
        }}
        gap={2}
      >
        {onDismiss && (
          <CloseButton
            onClick={(e) => {
              e.stopPropagation()
              onClose()
              onDismiss()
            }}
          />
        )}
      </Flex>
    </BaseAlert>
  ) : null
}
