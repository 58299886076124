import { useQuery } from '@tanstack/react-query'
import type { Currency } from '@strike-apps/strike/api-client'
import { fetch, type StrikeFetchError } from '@strike-apps/strike/fetch'
import type { ErrorDetails } from '@strike-apps/shared/next-api/models'
import type { SendMaxResponse } from '@ln-zap/consumer-api'
import { GET_MAX_SEND } from './queries'

export function useMaxSend(sourceCurrency?: Currency) {
  return useQuery<SendMaxResponse, StrikeFetchError<ErrorDetails>>(
    [GET_MAX_SEND, sourceCurrency],
    () => fetch<SendMaxResponse>(`/api/send/max/${sourceCurrency}`, { method: 'GET' }),
    { staleTime: 5000 },
  )
}
