import { Currency } from '@strike-apps/strike/api-client'
import { useUserInfo } from './useUserInfo'

export const useSettledBalance = (kind: 'cash' | 'bitcoin') => {
  const userInfoQuery = useUserInfo()
  const userInfo = userInfoQuery.data
  const balances = userInfo?.balances ?? []
  const settledBalance = balances.find((b) =>
    kind === 'bitcoin' ? b.currency === Currency.BTC : b.currency === userInfo?.defaultFiatCurrency,
  )?.settledBalance.amount

  return { ...userInfoQuery, data: settledBalance ?? null }
}
