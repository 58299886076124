export const GET_PROFILE = 'get-profile'
export const GET_BALANCE_QUERY = 'get-balance'
export const GET_LIMITS_QUERY = 'get-limits'
export const GET_DEPOSIT_FEE_ESTIMATE = 'get-deposit-fee-estimate'
export const GET_STATES = 'get-states'
export const BITCOIN_UNIT_QUERY = 'bitcoin-unit'
export const USER_INFO_QUERY = 'user-info'
export const PAYMENT_METHOD_TYPES_QUERY = 'payment-method-types'
export const GET_ACCOUNT_STATEMENTS = 'get-account-statements'
export const GET_TAX_DOCUMENTS = 'get-tax-documents'
export const GET_MAX_SEND = 'get-max-send'
export const GET_BALANCES_OVERVIEW = 'get-balances-overview'
export const GET_USER_TASK = 'get-user-task'
