import { useQuery } from '@tanstack/react-query'
import { fetch, type StrikeFetchError } from '@strike-apps/strike/fetch'
import type { ErrorDetails } from '@strike-apps/shared/next-api/models'
import type { InfoCountry, StatePoco } from '@ln-zap/consumer-api'
import { GET_STATES } from './queries'
import Bugsnag from '@bugsnag/js'

export interface SupportedState {
  code: string
  name: string
  isSupported: true
}

export const useSupportedStates = (countryCode?: string) => {
  return useQuery<SupportedState[], StrikeFetchError<ErrorDetails>>({
    queryKey: [GET_STATES, countryCode],
    queryFn: async () => {
      const { states } = await fetch<InfoCountry>(`/api/info/countries/${countryCode}`, {
        method: 'GET',
      })
      const isSupportedState = (state: StatePoco): state is SupportedState => {
        return (
          typeof state.code === 'string' &&
          typeof state.name === 'string' &&
          Boolean(state.isSupported)
        )
      }

      return states ? states.filter(isSupportedState) : []
    },
    enabled: Boolean(countryCode),
    onError: Bugsnag.notify,
  })
}
