import { useEnvSettings } from '@strike-apps/commerce-dashboard/env-settings'
import type { TextProps } from '@strike-apps/shared/ui'
import { Text } from '@strike-apps/shared/ui'

export function SandboxBadge(props: TextProps) {
  const { isSandboxEnvironment } = useEnvSettings()

  if (!isSandboxEnvironment) {
    return null
  }

  return (
    <Text
      px={2}
      py={1}
      borderRadius={8}
      borderColor="borderPrimary"
      borderWidth="1px"
      bg="objectAccentDisabled"
      variant="mono2"
      color="faceAccent"
      {...props}
    >
      Sandbox
    </Text>
  )
}
