import type { ComponentMultiStyleConfig } from '@chakra-ui/theme'

export const alertStyles: ComponentMultiStyleConfig = {
  parts: ['description', 'title'],
  variants: {
    error: {
      title: {
        color: 'faceNegative',
        textStyle: 'subheadline2',
      },
      description: {
        color: 'faceSecondary',
        textStyle: 'body3',
      },
    },
    normal: {
      title: {
        color: 'facePrimary',
        textStyle: 'subheadline2',
      },
      description: {
        color: 'faceSecondary',
        textAlign: 'center',
        textStyle: 'body3',
      },
    },
  },
  baseStyle: () => ({
    container: {
      bg: 'layerSecondary',
      px: 6,
      py: 4,
      borderRadius: 12,
    },
  }),
}
