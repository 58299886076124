const redirectBasePath = '/restricted'

export const BLOCKED_REGION_ROADBLOCK_PATH = `${redirectBasePath}/blocked-region`
export const DISABLED_ROADBLOCK_PATH = `${redirectBasePath}/blocked`
export const CLOSED_ROADBLOCK_PATH = `${redirectBasePath}/closed`
export const CLOSED_FINAL_ROADBLOCK_PATH = `${redirectBasePath}/closed-final`
export const CLOSED_REUSED_IDENTITY_ROADBLOCK_PATH = `${redirectBasePath}/closed-reused-identity`
export const CLOSED_SCAM_FRICTION_ROADBLOCK_PATH = `${redirectBasePath}/closed-scam-friction`
export const PAUSED_ROADBLOCK_PATH = `${redirectBasePath}/paused`
export const MAINTENANCE_MODE_ROADBLOCK_PATH = `${redirectBasePath}/maintenance-mode`
