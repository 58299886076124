import { isStrikeFetchError } from '@strike-apps/strike/fetch'
import { isErrorWithCode } from '@strike-apps/shared/utils'
import {
  BLOCKED_REGION_ROADBLOCK_PATH,
  CLOSED_FINAL_ROADBLOCK_PATH,
  CLOSED_ROADBLOCK_PATH,
  CLOSED_REUSED_IDENTITY_ROADBLOCK_PATH,
  CLOSED_SCAM_FRICTION_ROADBLOCK_PATH,
  DISABLED_ROADBLOCK_PATH,
  MAINTENANCE_MODE_ROADBLOCK_PATH,
  PAUSED_ROADBLOCK_PATH,
} from './constants'

export const getRoadblockRedirectDestinationFromError = (error: unknown) => {
  if (!isStrikeFetchError(error) || !isErrorWithCode(error)) {
    return null
  }

  if (error.response.status === 403) {
    return (
      {
        BLOCKED_REGION: BLOCKED_REGION_ROADBLOCK_PATH,
        DISABLED: DISABLED_ROADBLOCK_PATH,
        CLOSED: CLOSED_ROADBLOCK_PATH,
        CLOSED_FINAL: CLOSED_FINAL_ROADBLOCK_PATH,
        CLOSED_REUSED_IDENTITY: CLOSED_REUSED_IDENTITY_ROADBLOCK_PATH,
        CLOSED_SCAM_FRICTION: CLOSED_SCAM_FRICTION_ROADBLOCK_PATH,
      }[error.data.code] ?? null
    )
  }

  if (error.response.status === 429) {
    const retryAfter = error.response.headers.get('retry-after')
    return retryAfter ? `${PAUSED_ROADBLOCK_PATH}?retryAfter=${retryAfter}` : PAUSED_ROADBLOCK_PATH
  }

  if (error.response.status === 503 && error.data.code === 'MAINTENANCE_MODE') {
    return MAINTENANCE_MODE_ROADBLOCK_PATH
  }

  return null
}
