import { useQuery } from '@tanstack/react-query'
import type { Currency } from '@strike-apps/strike/api-client'
import { fetch, type StrikeFetchError } from '@strike-apps/strike/fetch'
import type { ErrorDetails } from '@strike-apps/shared/next-api/models'
import type { BalancesOverviewResponse } from '@ln-zap/consumer-api'
import { GET_BALANCES_OVERVIEW } from './queries'

export function useBalancesOverview(sourceCurrency?: Currency) {
  return useQuery<BalancesOverviewResponse, StrikeFetchError<ErrorDetails>>(
    [GET_BALANCES_OVERVIEW, sourceCurrency],
    () => fetch<BalancesOverviewResponse>(`/api/balances/${sourceCurrency}`, { method: 'GET' }),
    {
      enabled: Boolean(sourceCurrency),
    },
  )
}
